








import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import TableChartData from './modelsTable';

@Component
export default class FormBar extends FormBase {
  data: TableChartData = {
    title: '',
    description: '',
    icon: '',
  };
}
